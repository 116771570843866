.itemImageParent {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.itemImage {
  width: 100%;
  height: 100%;
  position: absolute;
}
