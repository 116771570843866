.cancelButton {
  width: 45px;
  height: 45px;
  font-size: 20pt;
  border-radius: 6px;
  border: none;
  color: white;
  margin-left: 7px;
  background-color: #ee0010;
}

.poweredBy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.poweredText {
  margin-bottom: 3px;
  color: #2a2a2a;
}

.checkoutGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  width: 100%;
  height: 90vh;
  padding: 30px;
  box-sizing: border-box;
  gap: 30px;
}
