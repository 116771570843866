.listItem {
  width: 100%;
  height: 200px;
  border-bottom: var(--gray) solid 2px;
  padding: 40px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 120px auto 120px;
  grid-template-rows: auto auto;
  gap: 20px;
}

.listItemImage {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.itemNameLabel {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-weight: 500;
  font-size: 20pt;
  display: flex;
  align-items: flex-end;
}

.locAndNutritionBox {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  align-items: flex-start;
}

.locAndNutritionPanel {
  background-color: var(--gray);
  display: flex;
  font-size: 14pt;
  align-items: center;
  border-radius: 12px;
}

.locAndNutritionPanel>* {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
}

.locAndNutritionPanel>:first-child {
  padding-left: 9px;
}

.locLabel {
  font-weight: 500;
}

.locAndNutritionPanel>:last-child {
  padding-right: 9px;
}

.locAndNutritionPanel>:not(:first-child) {
  border-left: 1px #cfcfcf solid;
  text-align: right;
  color: rgb(50, 50, 50);
  font-weight: 300;
}

.locAndNutritionPanel>:nth-child(2) {
  border-left: 2px #bababa solid;
}

.buyButton {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  background-color: var(--green);
  border-radius: 20px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.buyButton>* {
  color: white;
  font-weight: 500;
  font-size: 10pt;
}

.buyButtonIcon {
  font-size: 20pt;
  opacity: 0.9;
}
