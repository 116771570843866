:root {
  --green: #62cf49;
  --gray: #e6e6e6;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contentBox {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 10vh;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  padding: 15px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;
  align-items: center;
  background-color: white;
  z-index: 1;
  position: relative;
}

.merchLogo {
  font-size: 32pt;
  font-weight: 500;
  justify-self: center;
}

.acmLogo {
  height: 100%;
  justify-self: start;
}

.palantirLogo {
  height: 50%;
  justify-self: end;
}

button:active:not(.disabled) {
  filter: brightness(85%);
}
